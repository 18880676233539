<template>
  <div>
    <h1>POST DATA</h1>
    <div>
      <form @submit.prevent="postData">
        <input type="text" placeholder="name" v-model.trim="formData.name" required />
        <br />
        <input type="email" placeholder="email" v-model.trim="formData.email" required />
        <br />
        <input type="password" placeholder="password" v-model.trim="formData.password" required />
        <br />
        <input
          type="password"
          placeholder="confirmation password"
          v-model.trim="formData.c_password"
          required
        />
        <br />
        <button type="submit">Save</button>
      </form>
    </div>
    <h5>{{eventMessage}}</h5>
    <div>
      <h2>Data :</h2>
      {{dataLists}}
    </div>
    <div>
      <h2>Pagination :</h2>
      {{pagination}}
    </div>
  </div>
</template>

<script>
/* disable eslint */
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        password: "",
        c_password: "",
      },
    };
  },
  computed: {
    ...mapGetters(["dataLists", "pagination", "eventMessage"]),
  },
  methods: {
    postData() {
      this.$store.commit("setApiUrl", "api/user/store");
      this.$store.commit("addData", this.formData);
    },
  },
};
</script>
